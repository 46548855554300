import { createSlice } from "@reduxjs/toolkit";
const initialStatus = {
  status: "LOADING",
  message: "",
};

const initialState = {
  myTemplates: {},
  myTemplatesStatus: initialStatus,
  myTemplate: {},
  myTemplateStatus: initialStatus,
  smartTemplates: {},
  smartTemplatesStatus: initialStatus,
  smartTemplate: {},
  smartTemplateStatus: initialStatus,
  emailTemplates: {},
  emailTemplatesStatus: initialStatus,
  emailTemplate: {},
  emailTemplateStatus: initialStatus,
  resetEmailTemplates: {},
  resetEmailTemplatesStatus: initialStatus,
  resetEmailTemplate: {},
  resetEmailTemplateStatus: initialStatus,
  smsTemplates: {},
  smsTemplatesStatus: initialStatus,
  smsTemplate: {},
  smsTemplateStatus: initialStatus,
  letterByReferenceTemplates: {},
  letterByReferenceTemplatesStatus: initialStatus,
  letterByReferenceTemplate: {},
  letterByReferenceTemplateStatus: initialStatus,
  toastStatus: { message: "", color: "", status: "LOADING" },
};

const reducers = {
  setMyTemplates(state, action) {
    const { payload } = action;
    state.myTemplates = payload;
  },
  setMyTemplatesStatus(state, action) {
    const { payload } = action;
    state.myTemplatesStatus = payload;
  },
  setMyTemplate(state, action) {
    const { payload } = action;
    state.myTemplate = payload;
  },
  setMyTemplateStatus(state, action) {
    const { payload } = action;
    state.myTemplateStatus = payload;
  },
  setSmartTemplates(state, action) {
    state.smartTemplates = action.payload;
  },
  setSmartTemplatesStatus(state, action) {
    const { payload } = action;
    state.smartTemplatesStatus = payload;
  },
  setSmartTemplate(state, action) {
    const { payload } = action;
    state.smartTemplate = payload;
  },
  setSmartTemplateStatus(state, action) {
    const { payload } = action;
    state.smartTemplateStatus = payload;
  },
  setEmailTemplates(state, action) {
    const { payload } = action;
    state.emailTemplates = payload;
  },
  setEmailTemplatesStatus(state, action) {
    const { payload } = action;
    state.emailTemplatesStatus = payload;
  },
  setEmailTemplate(state, action) {
    const { payload } = action;
    state.emailTemplate = payload;
  },
  setEmailTemplateStatus(state, action) {
    const { payload } = action;
    state.emailTemplateStatus = payload;
  },

  setResetEmailTemplates(state, action) {
    const { payload } = action;
    state.resetEmailTemplates = payload;
  },
  setResetEmailTemplatesStatus(state, action) {
    const { payload } = action;
    state.resetEmailTemplatesStatus = payload;
  },
  setResetEmailTemplate(state, action) {
    const { payload } = action;
    state.resetEmailTemplate = payload;
  },
  setResetEmailTemplateStatus(state, action) {
    const { payload } = action;
    state.resetEmailTemplateStatus = payload;
  },
  setSmsTemplates(state, action) {
    const { payload } = action;
    state.smsTemplates = payload;
  },
  setSmsTemplatesStatus(state, action) {
    const { payload } = action;
    state.smsTemplatesStatus = payload;
  },
  setSmsTemplate(state, action) {
    const { payload } = action;
    state.smsTemplate = payload;
  },
  setSmsTemplateStatus(state, action) {
    const { payload } = action;
    state.smsTemplateStatus = payload;
  },
  setLetterByReferenceTemplates(state, action) {
    const { payload } = action;
    state.letterByReferenceTemplates = payload;
  },
  setLetterByReferenceTemplatesStatus(state, action) {
    const { payload } = action;
    state.letterByReferenceTemplatesStatus = payload;
  },
  setLetterByReferenceTemplate(state, action) {
    const { payload } = action;
    state.letterByReferenceTemplate = payload;
  },
  setLetterByReferenceTemplateStatus(state, action) {
    const { payload } = action;
    state.letterByReferenceTemplateStatus = payload;
  },
  setToastStatus(state, action) {
    const { payload } = action;
    state.toastStatus = payload;
  },
};

const templateSlice = createSlice({
  name: "template",
  initialState,
  reducers,
});

export const {
  setMyTemplates,
  setMyTemplatesStatus,
  setMyTemplate,
  setMyTemplateStatus,
  setSmartTemplates,
  setSmartTemplatesStatus,
  setSmartTemplate,
  setSmartTemplateStatus,
  setEmailTemplates,
  setEmailTemplatesStatus,
  setEmailTemplate,
  setEmailTemplateStatus,
  setResetEmailTemplates,
  setResetEmailTemplatesStatus,
  setResetEmailTemplate,
  setResetEmailTemplateStatus,
  setSmsTemplates,
  setSmsTemplatesStatus,
  setSmsTemplate,
  setSmsTemplateStatus,
  setLetterByReferenceTemplates,
  setLetterByReferenceTemplatesStatus,
  setLetterByReferenceTemplate,
  setLetterByReferenceTemplateStatus,
  setToastStatus,
} = templateSlice.actions;
export default templateSlice.reducer;
