import { toast } from "react-hot-toast";
import {
  USER_STORAGE_KEY,
  get,
  updateToken,
} from "../../helpers/api/api_helper";

export const uuid = () => {
  const crypto = window.crypto || window.msCrypto;
  let array = new Uint32Array(1);
  let randomValue = crypto.getRandomValues(array);
  return new Date().getTime().toString(36) + randomValue[0].toString();
};
export const addUrl = async (url, sortBy, sortDir, search) => {
  const token = updateToken();
  if (sortBy && sortDir) {
    url += `&sort_column=${sortBy}&sort_direction=${sortDir}`;
  }
  if (search) {
    url += `&search=${search}`;
  }
  const response = await get(url, {
    headers: { Authorization: `bearer ${token}` },
  });
  return response;
};

const validateRule = (rule) => {
  return (
    (!rule.value || rule.value.length === 0) &&
    rule.operator !== "null" &&
    rule.operator !== "notNull" &&
    rule.operator !== "empty" &&
    rule.operator !== "not_empty"
  );
};
const showErrorToast = () => {
  toast.error("Please fill all the required fields!", {
    position: "top-right",
  });
};
export const queryValidator = (rules) => {
  if (rules.length === 0) {
    toast.error("Please select a condition!", {
      position: "top-right",
    });
    return false;
  }

  for (const rule of rules) {
    if (rule.rules) {
      if (rule.rules.length === 0) {
        toast.error("Please select a condition!", {
          position: "top-right",
        });
        return false;
      }
      for (const childRule of rule.rules) {
        if (validateRule(childRule)) {
          showErrorToast();
          return false;
        }
      }
    } else if (validateRule(rule)) {
      showErrorToast();
      return false;
    }
  }
  return true;
};
export const checkConditions = (rules) => {
  if (rules.length === 0) {
    return false;
  }
  let segmentCount = 0
  for (const rule of rules) {
    if (rule.rules) {
      if (rule.rules.length === 0) {
        return false;
      }
      for (const childRule of rule.rules) {
        if (validateRule(childRule)) {
          return false;
        } else if (childRule.type !== "event") {
          segmentCount++
        }
      }
    } else if (validateRule(rule)) {
      return false;
    } else if (rule.type !== "event") {
      segmentCount++
    }
  }
  return segmentCount > 0;
};

export const handleLengthChange = (e, characters, title, handleChange) => {
  const { value } = e.target;
  value.length < characters && handleChange(e);
  value.length == characters &&
    toast.error(`${title} must not exceed ${characters - 1} characters.`, {
      position: "top-right",
    });
};
export const initialStatus = {
  status: "LOADING",
  message: "",
};

export const initialQuery = {
  combinator: "and",
  rules: [],
};

export const textRegex =
  /[\u{1F300}-\u{1F5FF}\u{1F600}-\u{1F64F}\u{1F680}-\u{1F6FF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{1F900}-\u{1F9FF}\u{1F1E0}-\u{1F1FF}]/u;

export const numberRegex = (val) => val.replace(/\D/g, "");

export const dateRegex =
  /^(19\d\d|20[0-4]\d|2050)-(0\d|1[0-2])-(0\d|[12]\d|3[01])$/;

export const catchErrorStatus = (dispatch, setErrorStatus, error) => {
  dispatch(
    setErrorStatus({
      status: "ERROR",
      message: error?.response?.data?.message,
      code: error?.response?.status,
    })
  );
};

export const checkErrorType = (e, dispatch, setToast) => {
  if (
    e.message === "Request failed with status code 422" ||
    e.message === "Request failed with status code 404"
  ) {
    if (e.response.data.errors) {
      for (let err in e.response.data.errors) {
        toast(e.response.data.errors[err][0], {
          position: "top-right",
          style: { backgroundColor: "#F04438" },
        });
      }
    } else {
      toast(e.response.data.message, {
        position: "top-right",
        style: { backgroundColor: "#F04438" },
      });
    }
  } else {
    dispatch(
      setToast({
        message: e.message,
        color: "#F04438",
        status: "ERROR",
      })
    );
  }
};

export const viewPermission = (title) => {
  if (window !== undefined) {
    const user = JSON.parse(localStorage.getItem(USER_STORAGE_KEY));
    if (user?.role?.permissions) {
      return user?.role?.permissions.find(
        (menu) => menu.title.toLowerCase() === title.toLowerCase()
      )?.view === 1
        ? title
        : null;
    }
  }
};

export const otherPermission = (title, action) => {
  if (window !== undefined) {
    const user = JSON.parse(localStorage.getItem(USER_STORAGE_KEY));
    if (user?.role?.permissions) {
      return user?.role?.permissions.find(
        (menu) => menu.title.toLowerCase() === title.toLowerCase()
      )?.[action.toLowerCase()] === 1
        ? action
        : null;
    }
  }
};
export const inNotInOp = [
  { name: "in", label: "in" },
  { name: "not_in", label: "not in" },
];
